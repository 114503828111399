@mixin pc {
	@media screen and (min-width: 1366px) {
		@content
	}
};
@mixin mobile{
	@media screen and (max-width: 1224px) {
		@content
	}
};
body{
	font-family: "Roboto", sans-serif;
}
h1, h3, h2{
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
}
img[src=""] {
	display: none;
}
.city1{
	height: auto;
	padding: 3em 2em;
	background-image: url('./assets/pc/10/1.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include mobile{
		background-image: url('./assets/phone/10/1.jpg');
		padding: 2em 1em;		
	}
	.city{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.divuno{
			display: flex;
			justify-content: flex-start;
			width: 100%;
		}
		.divdos{
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 100%;
			@include mobile{
				flex-direction: column;
			}
			.divdosuno{
				flex: 1;
				& p{
					font-size: 1.7em
				}
				& li{
					font-size: 1.5em;
				}
				.options{
					display: flex;
					flex-direction: row;
					align-items: center;
				}
			}
			.divdosdos{
				flex: 1;
				width: 100%;
				&>img{
					width: 100%;
				}
			}
		}
		.divtres{
			margin-top: 2em;
			@include mobile{
				&>img{
					width: 250px;
				}
			}
		}
	}
}

.city2{
	min-height: 100vh;
	padding: 3em 2em;
	background-image: url('./assets/fondo.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	color: white;
	@include mobile{
		background-image: url('./assets/fondo.png');
		padding: 2em 1em;		
	}
	.city{
		min-height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		& p{
			width: 70%;
			text-align: center;
			font-size: 1.6em;
			@include mobile{
				font-size: 1em;
				width: 90%;
			}
		}
		&>img{
			margin-top: 2em;
			@include mobile{
				width: 250px;
			}
		}
	}
}

.elect1{
	height: auto;
	padding: 3em 2em;
	background-image: url('./assets/pc/3/1.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include mobile{
		background-image: url('./assets/phone/3/1.jpg');
		padding: 2em 1em;		
	}
	.city{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		.divuno{
			display: flex;
			justify-content: flex-start;
			width: 100%;
		}
		.divdos{
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 100%;
			@include mobile{
				flex-direction: column;
			}
			.divdosuno{
				flex: 1;
				margin-left: 2em;
				& p{
					font-size: 1.7em
				}
				& li{
					font-size: 1.5em;
				}
				.options{
					display: flex;
					flex-direction: row;
					align-items: center;
				}
			}
			.divdosdos{
				flex: 1;
				width: 100%;
				&>img{
					width: 100%;
				}
			}
		}
		.divtres{
			margin-top: 2em;
			@include mobile{
				&>img{
					width: 250px;
				}
			}
		}
	}
}

.about{
	padding: 3em 2em;
	color: black;
	background-image: url('./assets/fondoblanco.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include mobile{
		background-image: url('./assets/fondoblanco.png');
		padding: 2em 1em;		
	}
	p{
		font-size: 1.2em;
		@include mobile{
			font-size: 1em;
		}
	}
	h2{
		font-size: 1.7em;
	}
	.mision{
		display: flex;
		align-items: center;
		@include mobile{
			flex-direction: column;
		}
		div{
			margin: 0 1em;
			@include mobile{
				margin: 0;
			}
		}
	}
}