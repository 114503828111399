@mixin pc {
	@media screen and (min-width: 1366px) {
		@content
	}
};
@mixin mobile{
	@media screen and (max-width: 1224px) {
		@content
	}
};
body{
	font-family: "Roboto", sans-serif;
}
h1, h3{
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
}
img[src=""] {
	display: none;
}
.footer{
    background-color: black;
    padding: 2em 0;
    .img-footer{
        display: flex;
        justify-content: center;
        &>img{
            width: 170px;
        }
    }
    .content{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        h3{
            color: #FB6300;
            @include mobile{
                font-size: 1.1em;
            }
        }
        div{
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            margin: 2em;
            &>a{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                color: white;
                text-decoration: none;
            }
            &>div{
                color: white;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                line-height: 5px;
                margin: 0.5em 0;
            }
        }
    }
}