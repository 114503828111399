@mixin pc {
  @media screen and (min-width: 1366px) {
      @content
  }
};
@mixin mobile{
  @media screen and (max-width: 1224px) {
      @content
  }
};
body{
  font-family: "Roboto", sans-serif;
}
h1, h3{
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
img[src=""] {
  display: none;
}
.page1{
  min-height: 100vh;
  background-image: url('./assets/pc/1/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/1/1.jpg');
    position: relative;
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    @include mobile{
      padding:0 1em
    }
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @include mobile{
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }
      &>h1{
        color: white;
        font-size: 3em;
        width: 80%;
        margin-top: -2em;
        @include mobile{
          margin-top: 3em;
          font-size: 2em;
        }
      }
      &>img{
        @include pc{
          display: none;
        }
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      @include mobile{
        display: none;
      }
      &>img{
        height: 80vh;
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      margin-top: 10em;
      @include mobile{
        margin-top: 15em;
        padding: 0;
      }
      &>div{
        &>h3{
          color: #FB6300;
          font-size: 2em;
          @include mobile{
            font-size: 1.5em;
          }
        }
        &>p{
          color: white;
          font-size: 1.2em;
          @include mobile{
            font-size: 0.9em;
          }
          &>span{
            color: #FB6300;
          }
        }
        &>img{
          width: 70%;
          margin-top: 1em;
          @include mobile{
            width: 180px;
            position: absolute;
            right: 1em;
            bottom: 2em;
          }
        }
      }
    }
  }
}

.page2{
  min-height: 100vh;
  background-image: url('./assets/pc/2/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/2/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    @include mobile{
      padding-left: 2em;
    }
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-left: 5em;
      @include mobile{
        margin-left: 0;
        margin-right: -7em;
      }
      &>div{
        &>h3{
          color: #FB6300;
          font-size: 2em;
          @include mobile{
            font-size: 1.5em;
          }
        }
        &>p{
          color: black;
          font-size: 1em;
          @include mobile{
            font-size: 0.8em;
          }
          &>span{
            color: #FB6300;
          }
        }
        &>img{
          width: 70%;
          margin-top: 1em;
          @include mobile{
            width: 180px;
          }
        }
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &>img{
        height: 60vh;
        @include mobile{
          height: 40vh;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      margin-top: 10em;
      @include mobile{
        display: none;
      }
    }
  }
}
.page3{
  min-height: 100vh;
  background-image: url('./assets/pc/3/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/3/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @include mobile{
        display: none;
      }
      &>h1{
        color: white;
        font-size: 3em;
        width: 80%;
        margin-top: -2em;
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &>img{
        height: 70vh;
        @include mobile{
          width: 100%;
          height: auto;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      @include mobile{
        min-width: 150px;
        margin-top: -15em;
      }
      &>div{
        &>h3{
          color: #FB6300;
          font-size: 2em;
        }
        &>p{
          color: white;
          font-size: 1.2em;
          @include mobile{
            font-size: 1em;
          }
        }
        &>span{
          color: #FB6300;
          font-size: 1.2em;
          @include mobile{
            font-size: 1em;
            text-shadow:
              0.5px 0.5px 0 #121246,
              -0.5px 0.5px 0 #121246,
              -0.5px -0.5px 0 #121246,
              0.5px -0.5px 0 #121246;
          }
        }
        &>img{
          width: 70%;
          margin-top: 1em;
          @include mobile{
            display: none
          }
        }
      }
    }
  }
}

.page4{
  min-height: 100vh;
  background-image: url('./assets/pc/4/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/4/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      &>div{
        width: 85%;
        @include mobile{
          width: 90%;
        }
        &>h3{
          color: #FB6300;
          font-size: 2em;
          @include mobile{
            font-size: 1.5em;
            text-shadow:
              0.5px 0.5px 0 #121246,
              -0.5px 0.5px 0 #121246,
              -0.5px -0.5px 0 #121246,
              0.5px -0.5px 0 #121246;
          }
        }
        &>p{
          color: black;
          font-size: 1em;
          &>span{
            color: #FB6300;
          }
        }
        &>img{
          width: 70%;
          margin-top: 10em;
          @include mobile{
            margin-top: 1em;
            width: 180px;
          }
        }
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &>img{
        height: 80vh;
        @include mobile{
          width: 100%;
          height: auto;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      margin-top: 10em;
      @include mobile{
        display: none;
      }
    }
  }
}

.page5{
  min-height: 100vh;
  background-image: url('./assets/pc/5/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position:relative;
  @include mobile{
    background-image: url('./assets/phone/5/1.jpg');
  }
  h1{
    position: absolute;
    color: white;
    top: 1em;
    left: 1em;
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mobile{
        justify-content: center;
      }
      &>div{
        width: 90%;
        @include mobile{
          text-align: center;
        }
        &>img{
          width: 30%;
          @include mobile{
            width: 45%;
          }
        }
      }
    }
    .divdos{
      flex: 1;
      display: flex;
      justify-content:center;
      align-items: center;
      flex-direction: column;
      &>div{
        width: 65%;
        @include mobile{
          width: 85%;
        }
        &>h3{
          color: #FB6300;
          font-size: 2.5em;
          @include mobile{
            font-size: 1.5em;
          }
        }
        &>p{
          color: white;
          font-size: 1em;
        }
        &>span{
          color: white;
          font-size: 1.5em;
          @include mobile{
            font-size: 1.2em;
          }
        }
      }
      &>img{
        margin-top: 4em;
        width: 70%;
        @include mobile{
          width: 250px;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          bottom: 2em;
        }
      }
    }
  }
}

.page6{
  min-height: 100vh;
  background-image: url('./assets/pc/6/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/6/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: center;
      &>div{
        width: 65%;
        &>h3{
          color: #FB6300;
          font-size: 2em;
        }
        &>p{
          color: black;
          font-size: 1em;
          &>span{
            color: #FB6300;
          }
        }
        &>img{
          width: 70%;
          margin-top: 10em;
        }
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &>img{
        height: 70vh;
        @include mobile{
          width: 100%;
          height: auto;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      margin-top: 10em;
      @include mobile{
        display: none;
      }
    }
  }
}

.page7{
  min-height: 100vh;
  background-image: url('./assets/pc/7/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/7/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @include mobile{
        display: none
      }
      &>h1{
        color: white;
        font-size: 3em;
        width: 80%;
        margin-top: -2em;
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &>img{
        height: 50vh;
        margin-bottom: 2em;
        @include mobile{
          width: 100%;
          height: auto;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      @include mobile{
        padding: 0 1em;
      }
      &>div{
        width: 90%;
        &>h3{
          color: #FB6300;
          font-size: 2em;
          @include mobile{
            font-size: 1.5em;
            text-shadow:
              0.5px 0.5px 0 #121246,
              -0.5px 0.5px 0 #121246,
              -0.5px -0.5px 0 #121246,
              0.5px -0.5px 0 #121246;
          }
        }
        &>p{
          color: white;
          font-size: 1.2em;
          @include mobile{
            font-size: 1em;
          }
        }
        &>span{
          color: white;
          font-size: 1.5em;
          font-weight: 600;
          @include mobile{
            font-size: 1.2em;
          }
        }
      }
    }
  }
}

.page8{
  min-height: 100vh;
  background-image: url('./assets/pc/8/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/8/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      &>div{
        width: 85%;
        @include mobile{
          width: 90%;
        }
        &>h3{
          color: #FB6300;
          font-size: 2em;
          @include mobile{
            font-size: 1.5em;
            text-shadow:
              0.5px 0.5px 0 #121246,
              -0.5px 0.5px 0 #121246,
              -0.5px -0.5px 0 #121246,
              0.5px -0.5px 0 #121246;
          }
        }
        &>p{
          color: black;
          font-size: 1em;
          &>span{
            color: #FB6300;
          }
        }
        &>img{
          width: 80%;
          margin-top: 10em;
          @include mobile{
            width: 100%;
            margin-top: 1em;
          }
        }
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &>img{
        height: 70vh;
        @include mobile{
          width: 100%;
          height: auto;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      margin-top: 10em;
      @include mobile{
        display: none;
      }
    }
  }
}

.page9{
  min-height: 100vh;
  background-image: url('./assets/pc/9/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/9/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @include mobile{
        display: none;
      }
      &>h1{
        color: white;
        font-size: 3em;
        width: 80%;
        margin-top: -2em;
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &>img{
        height: 75vh;
        @include mobile{
          width: 100%;
          height: auto;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      @include mobile{
        padding: 0 1em;
      }
      &>div{
        width: 80%;
        @include mobile{
          width: 90%;
        }
        &>h3{
          color: #FB6300;
          font-size: 2em;
          @include mobile{
            font-size: 1.5em;
            text-shadow:
              0.5px 0.5px 0 #121246,
              -0.5px 0.5px 0 #121246,
              -0.5px -0.5px 0 #121246,
              0.5px -0.5px 0 #121246;
          }
        }
        &>p{
          color: white;
          font-size: 1.2em;
          @include mobile{
            font-size: 1em;
          }
        }
        &>span{
          color: white;
          font-size: 1.5em;
          font-weight: 600;
          @include mobile{
            font-size: 1.2em;
          }
        }
      }
    }
  }
}

.page10{
  min-height: 100vh;
  background-image: url('./assets/pc/10/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/10/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      &>div{
        width: 85%;
        &>h3{
          color: #FB6300;
          font-size: 2em;
          @include mobile{
            font-size: 1.5em;
            text-shadow:
              0.5px 0.5px 0 #121246,
              -0.5px 0.5px 0 #121246,
              -0.5px -0.5px 0 #121246,
              0.5px -0.5px 0 #121246;
          }
        }
        &>p{
          color: black;
          font-size: 1em;
          &>span{
            color: #FB6300;
          }
        }
        &>img{
          width: 80%;
          margin-top: 10em;
        }
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &>img{
        height: 40vh;
        margin-bottom: 2em;
        @include mobile{
          width: 100%;
          height: auto;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      margin-top: 10em;
      @include mobile{
        display: none;
      }
    }
  }
}

.page11{
  min-height: 100vh;
  background-image: url('./assets/pc/11/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/11/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @include mobile{
        display: none
      }
      &>h1{
        color: white;
        font-size: 3em;
        width: 80%;
        margin-top: -2em;
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &>img{
        height: 80vh;
        @include mobile{
          width: 100%;
          height: auto;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      &>div{
        width: 80%;
        @include mobile{
          width: 90%;
        }
        &>h3{
          color: #FB6300;
          font-size: 2em;
          @include mobile{
            font-size: 1.5em;
            text-shadow:
              0.5px 0.5px 0 #121246,
              -0.5px 0.5px 0 #121246,
              -0.5px -0.5px 0 #121246,
              0.5px -0.5px 0 #121246;
          }
        }
        &>p{
          color: white;
          font-size: 1.2em;
          @include mobile{
            font-size: 1em;
          }
        }
        &>span{
          color: white;
          font-size: 1.5em;
          font-weight: 600;
          @include mobile{
            font-size: 1.2em;
          }
        }
        &>img{
          width: 70%;
          margin-top: 3em;
          @include mobile{
            width: 180px;
            margin-top: 1em;
          }
        }
      }
    }
  }
}

.page12{
  min-height: 100vh;
  background-image: url('./assets/pc/12/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/12/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      &>div{
        width: 85%;
        @include mobile{
          width: 90%;
          margin-top: -5em;
        }
        &>h3{
          color: #FB6300;
          font-size: 2em;
          @include mobile{
            font-size: 1.5em;
            text-shadow:
              0.5px 0.5px 0 #121246,
              -0.5px 0.5px 0 #121246,
              -0.5px -0.5px 0 #121246,
              0.5px -0.5px 0 #121246;
          }
        }
        &>p{
          color: black;
          font-size: 1em;
          &>span{
            color: #FB6300;
          }
        }
        &>img{
          width: 80%;
          margin-top: 10em;
        }
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &>img{
        height: 40vh;
        margin-bottom: 2em;
        @include mobile{
          display: none;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      margin-top: 10em;
    }
  }
}

.page13{
  min-height: 100vh;
  background-image: url('./assets/pc/13/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/13/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @include mobile{
        display: none;
      }
      &>h1{
        color: white;
        font-size: 3em;
        width: 80%;
        margin-top: -2em;
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &>img{
        height: 75vh;
        @include mobile{
          width: 100%;
          height: auto;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      &>div{
        width: 80%;
        @include mobile{
          width: 90%;
        }
        &>h3{
          color: #FB6300;
          font-size: 2em;
          @include mobile{
            font-size: 1.5em;
            text-shadow:
              0.5px 0.5px 0 #121246,
              -0.5px 0.5px 0 #121246,
              -0.5px -0.5px 0 #121246,
              0.5px -0.5px 0 #121246;
          }
        }
        &>p{
          color: white;
          font-size: 1.2em;
          @include mobile{
            font-size: 1em;
          }
        }
        &>span{
          color: white;
          font-size: 1.5em;
          font-weight: 600;
          @include mobile{
            font-size: 1.2em;
          }
        }
      }
    }
  }
}

.page14{
  min-height: 100vh;
  background-image: url('./assets/pc/14/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/14/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      &>div{
        width: 85%;
        &>h3{
          color: #FB6300;
          font-size: 2em;
          @include mobile{
            font-size: 1.5em;
            text-shadow:
              0.5px 0.5px 0 #121246,
              -0.5px 0.5px 0 #121246,
              -0.5px -0.5px 0 #121246,
              0.5px -0.5px 0 #121246;
          }
        }
        &>p{
          color: black;
          font-size: 1em;
          &>span{
            color: #FB6300;
          }
        }
        &>img{
          width: 80%;
          margin-top: 5em;
          @include mobile{
            width: 180px;
            margin-top: 1em;
          }
        }
      }
    }
    .divdos{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &>img{
        height: 60vh;
        @include mobile{
          width: 100%;
          height: auto;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: left;
      padding: 0 2em;
      margin-top: 10em;
      @include mobile{
        display: none;
      }
    }
  }
}

.page15{
  min-height: 100vh;
  background-image: url('./assets/pc/15/1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include mobile{
    background-image: url('./assets/phone/15/1.jpg');
  }
  .page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    .divuno{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @include mobile{
        display: none;
      }
      &>h1{
        color: white;
        font-size: 3em;
        width: 80%;
        margin-top: -2em;
      }
    }
    .divdos{
      position: absolute;
      right: 1em;
      bottom: 1em;
      &>img{
        height: 100px;
        @include mobile{
          width: 100px;
          height: auto;
        }
      }
    }
    .divtres{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      text-align: left;
      padding: 0 3em;
      margin-top: -13em;
      @include mobile{
        align-items: flex-start;
      }
      &>div{
        width: 60%;
        &>h3{
          color: #FB6300;
          font-size: 2em;
          @include mobile{
            font-size: 1.5em;
          }
        }
        &>p{
          color: white;
          font-size: 1.2em;
          @include mobile{
            font-size: 1em;
          }
          &>span{
            color: white;
            font-size: 1.5em;
            font-weight: 600;
            @include mobile{
              font-size: 1.2em;
            }
          }
        }
        &>img{
          width: 80%;
          margin-top: 3em;
          @include mobile{
            width: 180px;
            margin-top: 1em;
          }
        }
      }
    }
    .divcuatro{
      position: absolute;
      left: 1em;
      bottom: 1em;
      @include mobile{
        &>div>img{
          width: 120px;
        }
      }
      .link{
        color: white;
        font-size: 1.3em;
      }
    }
  }
}