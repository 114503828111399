body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EEEEEE; /* Fondo gris */
    padding: 0 10px;
  }
  .navbar a, .navbar .icon-left {
    color: #FB6300;
    font-weight: 500;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
  }
  .navbar a:hover {
    background-color: #EEEEEE;
  }
  .navbar a.active {
    background-color: #4CAF50;
    color: #FB6300;
  }
  .navbar .icon-left {
    font-size: 24px;
  }
  .navbar .menu {
    display: flex;
  }
  .navbar .icon {
    display: none;
  }
  @media screen and (max-width: 1200px) {
    .navbar .menu a {
      display: none;
    }
    .navbar .icon {
      display: block;
      z-index: 1;
    }
  }
  @media screen and (max-width: 1200px) {
    .navbar.responsive .menu {
      position: relative;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
    .navbar.responsive .menu a {
      display: block;
      text-align: left;
      width: 100%;
    }
  }

.icon-responsive{
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #FB6300;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.icon-close{
  align-self: flex-start;
  font-size: 1.5em;
}
  