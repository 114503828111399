@mixin pc {
	@media screen and (min-width: 1366px) {
		@content
	}
};
@mixin mobile{
	@media screen and (max-width: 1224px) {
		@content
	}
};
body{
	font-family: "Roboto", sans-serif;
}
h1, h3{
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
}
img[src=""] {
	display: none;
}
.faq{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2em;
    .faq-list{
        width: 90%;
        padding: 2em 0;
        @include mobile{
            width: 100%;
        }
        .itemBtn{
            @include mobile{
                font-size: 1em;
            }
        }
    }
    .img{
        align-self: flex-end;
        &>img{
            @include mobile{
                height: 70px;
            }
        }
    }
    .title{
        &>h1{
            @include mobile{
                font-size: 1.5em;
            } 
        }
    }
}